import React from "react";
import { Link, withPrefix, graphql } from "gatsby";
import Layout from "../components/layout";

import SEO from "../components/seo";

import BlogListItem from "../components/BlogListItem";
import BackgroundImage from "gatsby-background-image";

import BlogPostListPage from "../components/blogPostListPage";

const Tag = ({ data, pageContext }) => {

  const blogPosts = data.list.nodes;
  const imageData = data.banner.childImageSharp.fluid;
  return (
    <>
    <BlogPostListPage tag={pageContext.identifier} tagName={pageContext.name} list={blogPosts} imageData={imageData} />
    </>
  );
};

export const query = graphql`
  query TagPage($slug: String!){
    list: allWpPost(filter: { tags: { nodes: {elemMatch: { slug: { eq: $slug } } }} }) {
      totalCount
      nodes {
        id
        title
        excerpt
        slug
        date(formatString: "DD MMMM YYYY")
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  placeholder: BLURRED
                  formats: [AVIF, WEBP, JPG]
                )
              }
            }
          }
        }
      }
    }
    banner: file(
      relativePath: { eq: "blog-executive-coach-angela-cooney-banner.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Tag;
